.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
}

@keyframes App-logo-spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}
