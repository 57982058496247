.BtnGradient{

    width:100%;
    background: -webkit-linear-gradient(to bottom, #f3f3f3, #e8e8e8); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3, #e8e8e8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  
    border-right: 1px solid #b7b7b7;



  }

  

  .BtnBg{
    background-image: url('../resource/posez/btnbg.svg') ;
    background-size: contain;
    width:100%;
    border-right: 1px solid #b7b7b7;
    border-left: 1px solid #ffffff;

  }

  
  .BtnBgNoBorder{
    background-image: url('../resource/posez/btnbg.svg');
    background-size: contain;
    border-left: 1px solid #ffffff;
    width:100%;
    
  }

  
  .BtnBgNoBorder2{
    background-image: url('../resource/posez/btnbg.svg');
    background-size: contain;
    border-right: 1px solid #b7b7b7;
    width:100%;
  }




  .BtnGradientNoBorder{

    width:100%;
    background: -webkit-linear-gradient(to bottom, #f3f3f3, #e8e8e8); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3, #e8e8e8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }


  .BtnGradient2{

    width:100%;
    background: -webkit-linear-gradient(to bottom, #f3f3f3, #e0e0e0); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3, #e0e0e0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  
    border-right: 1px solid #b7b7b7;



  }

  .BtnGradientNoBorder2{

    width:100%;
    background: -webkit-linear-gradient(to bottom, #f3f3f3, #e0e0e0); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3, #e0e0e0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  }

  .BtnGradientNoBorder3{

    width:100%;
    background: -webkit-linear-gradient(to bottom, #ffffff,  #f8f8f8); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #ffffff, #f8f8f8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  }

  .ScrollStyleE8E8E8{
    width:50px;
    height:100%;
   background-color:#e8e8e8;
    

  }
  

  .ScrollStyleE0E0E0{
    width:50px;
    height:50%;
    background-color:#e0e0e0; 
 

  }
  
  .ScrollStyleFFFFFF{
    width:50px;
    height:50%;
    background-color:#ffffff; 
 

  }

  .InputCss{
   
    flex:1;
    padding:15px;
    background-color: #e8e8e8;
    border-top:0px;
    border-left: 0px;
    border-right:0px;
    border-bottom: 1px solid #b7b7b7;
    color:#000000;   
 
   

  }

  .InputCss:focus{
    outline: none;
    background-color: #f3f3f3;
    
  }

  .InputCss::placeholder{
    color:#b7b7b7;
  }
  .InputCss::-moz-placeholder{
    color:#b7b7b7;
  }
  .InputCss::-ms-input-placeholder{
    color:#b7b7b7;
  }
  .InputCss::-moz-placeholder{
    color:#b7b7b7;
  }
  