.mainLayout{
    width:100vw;
    height:100vh;
    box-sizing: border-box;
    position: fixed;
    overflow: hidden;
    display:flex;
    flex:1;
    flex-direction: column;
    color:#5d5d5d;
    font-size:15px;
    scrollbar-width: none;
}

.mainLayout::-webkit-scrollbar{
    display: none;
}

.topLayout{
    position:fixed;
    top:0;
    display:flex;
    flex-direction:column;
    width:100%;
}

.topLayout >span:nth-child(1){
    background-color: #e0e0e0;
    width:100vw;
    height:60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:20px;
    box-sizing: border-box;
    color:#323232;
}

.topLayout >span:nth-child(2){
    background-color: #e0e0e0;
    width:100vw;
    height:70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
}

.topLeft{
    min-width:fit-content;
    display:flex;
    flex-direction:row;
    align-items: center;
    
}

.topRight{

    display:flex;
    flex:1;
    min-width:fit-content;
    align-items: center;
    justify-content: flex-end;
}


.topLayout .topLeft img{
    width:90px;
    margin-right:15px;
}

.topLayout .topRight img{
    padding:8px;        
    border-radius:10px;
    border:2px solid #323232;
    background-color: #323232;
    margin-left:10px;
    width:17px;
    height:17px;
}

.centerLayout{
    display:flex;
    flex:1;
    background-color:#eeeeee; 
    border:1px solid #cccccc;
    border-top:0px;
    overflow:scroll;
    scrollbar-width: none;
}
.centerLayout::-webkit-scrollbar{
    display: none;
}

.centerLeft{
    display: flex;
    flex:1;
    overflow:scroll;
    padding:10px;
    box-sizing: border-box;
}

.centerRight{
    display: flex;
    flex:1;
    overflow:hidden;
    padding:10px;
    box-sizing: border-box;
}


.datepicker{
    border-bottom:0px;
    width:200px;
}
.rbOn0{
    border-top: 5px solid #f7bb26;
    color:#f7bb26;
    box-sizing: border-box;
    margin-top:5px;
    height:65px;
    min-height: 65px;
    max-height: 65px;
    width:150px;
    background-color: #eeeeee;
    border-right:1px solid #cccccc;
    border-left:1px solid #cccccc;
    border-bottom:1px solid #eeeeee;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

.rbOn1{
    border-top: 5px solid #048e7d;
    color:#048e7d;
    box-sizing: border-box;
    margin-top:5px;
    height:65px;
    min-height: 65px;
    max-height: 65px;
    width:150px;
    background-color: #eeeeee;
    border-right:1px solid #cccccc;
    border-left:1px solid #cccccc;
      border-bottom:1px solid #eeeeee;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

 

.rbOn2{
    border-top: 5px solid #5d5d5d;
    color:#5d5d5d;
    box-sizing: border-box;
    margin-top:5px;
    height:65px;
    min-height: 65px;
    max-height: 65px;
    width:150px;
    background-color: #eeeeee;
    border-right:1px solid #cccccc;
    border-left:1px solid #cccccc;
    border-bottom:1px solid #eeeeee;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.rbOn3{
    border-top: 5px solid #969696;
    color:#969696;
    box-sizing: border-box;
    margin-top:5px;
    height:65px;
    min-height: 65px;
    max-height: 65px;
    width:150px;
    background-color: #eeeeee;
    border-right:1px solid #cccccc;
    border-left:1px solid #cccccc;
    border-bottom:1px solid #eeeeee;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.rbOff{
    box-sizing: border-box;
    margin-top:5px;
    height:65px;
    min-height: 65px;
    max-height: 65px;
    width:150px;
    font-size: 18px;
    font-weight: 700;
    color:#969696;
    border-top:5px solid #00000000;
    border-right:1px solid #e0e0e0;
    border-left:1px solid #e0e0e0;
    border-bottom:1px solid #cccccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
}

.orderBtn{
    display:flex;
    flex-direction:column;
    align-items:center;
}

.orderBtn div{
    font-size:18px;
    font-weight:700; 
}

.centerLeft{
    scrollbar-width: none;
}

.centerLeft::-webkit-scrollbar{
    display: none;
}

.orderList{
    width:100%;
    background-color: #ffffff; 
    color:#bbbbbb;
    padding:15px;
    margin:5px 0px 15px 5px;
    box-sizing: border-box;
    height:fit-content;
    display: flex;
    flex-direction: row;
    border:1px solid #eeeeee;
}



.orderListCk{
    width:100%;
    background-color: #ffffff;
    box-shadow: 3px 3px 0px #bbbbbb;
    padding:15px;
    margin:5px 0px 15px 5px;
    box-sizing: border-box;
    height:fit-content;
    display: flex;
    flex-direction: row;
    scrollbar-width: none;
    border:1px solid #aaaaaa;
}

.orderListCk::-webkit-scrollbar{
    display: none;
}


.orderDetail{
    width:100%;
    background-color: #ffffff; 
    margin:5px 5px 5px 0px;
    box-sizing: border-box;
    height:calc(100% - 10px);
    overflow: hidden;
    box-shadow: 3px 3px 0px #bbbbbb;
    border:1px solid #aaaaaa;
}
 
 
.leftBox {
    display: flex;
    align-items: flex-start;
    flex:1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.leftBox span{
    font-weight: 700;
}

.leftBox span:nth-child(2){
    font-size: 18px;
    margin-top:8px;
}

.leftBox span:nth-child(3){
    font-size: 22px;
    margin-top:3px;
    margin-bottom:8px;
}

.cusOrderInfo{
    font-size: 16px;
    color:#5d5d5d; 
}
 
.cusOrderInfo div:nth-child(2){
    line-height: 1.4;
}


.orderCost{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
}

.rightBox{
    margin-left: 10px;
    font-weight: 700;
    width:fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.rightBox div{
    display: flex;
    flex:1;
}

.rightBox span{
    min-height: fit-content; 
}

.btnOrder{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width:70px;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    max-height: 70px;
    min-height: 70px; 
    border-radius: 50px; 
    font-weight: 700;
    position:relative;
    background-color: #f5b516;
    color:#ffffff;
}

.btnOrderCompleteCancel{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width:70px;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    max-height: 70px;
    min-height: 70px; 
    border-radius: 50px; 
    font-weight: 700;
    position:relative;
    color:#f44336;
    background-color: #f4433610;
}
.btnOrderComplete{
   
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width:70px;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    max-height: 70px;
    min-height: 70px; 
    border-radius: 50px; 
    font-weight: 700;
    position:relative;
    color:#969696;
    background-color: #96969610;
}


.right-top{
    display: flex;
    flex-direction: column;
    width:100%; 
    padding:15px;
    box-sizing: border-box;
    border-bottom-style: dotted;
    border-bottom-color: #dcdcdc;
    border-bottom-width: 1.5px;
    height:115px;
    min-height: 115px;
    max-height: 115px;
}

.right-top div:nth-child(1){
    display: flex;
    flex-direction: row;
    flex:1;
    height:30px;
    min-height: 30px;
    max-height: 30px;
}

.right-top div:nth-child(1) span:nth-child(1){
    display: flex;
    flex: 1;
    text-align: left;
    margin-right: 10px;
    font-size: 20px;
    font-weight: 700;
    color:#323232;
}

 
.right-top img{
    width:20px;
    height:20px;
}
 

.right-top div:nth-child(2){
    display: flex;
    flex-direction: row;
    flex:1;
    margin-top: 5px;
    width:100%;
    box-shadow: 1px 1px 0px #eeeeee;
    color:#323232;

}

.right-top button{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    height:50px; 
    font-size: 16px;
    font-weight: 700;
    color:#5d5d5d;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;

    box-sizing: border-box;
}


.right-middle{
    width:100%;
    height:calc(100vh - 360px); 
    padding-top:10px;
    overflow-y: scroll;
    scrollbar-width: none;
}
.right-middle::-webkit-scrollbar{
    display: none;
}
.right-bottom{ 
    height:70px;
    min-height: 70px;
    max-height: 70px;
    display: flex;
    flex:1;
    flex-direction: row;
    width:100%;
    align-items: center;
    padding:0px 15px 0px 15px;
    box-sizing: border-box;
    justify-content: center;
    box-shadow: 1px 1px 0px #eeeeee;
}

.right-bottom button{

    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    height:40px;
    font-size: 14px;
    font-weight: 600;
    color:#5d5d5d;
    background-color: #ffffff;
    border: 1px solid #dcdcdc; 

    box-sizing: border-box;
}

.orderContent{
    display: flex;
    flex:1;
    flex-direction: row;
    width:100%;
    box-sizing: border-box;
    padding:0px 20px 0px 20px;
    font-size: 16px;
    line-height: 1.8;
    color:#5d5d5d;
}

.orderContent div:nth-child(1){
    min-width: fit-content;
}

.orderContent div:nth-child(2){
    display: flex;
    flex:1;
    justify-content: flex-end;
    text-align: right;
    margin-left:10px;
}

.orderContent div:nth-child(3){ 
    text-align: right;
    min-width: fit-content;
    margin-left: 10px;
}

.receipt-item{
    width:100%;
    display: flex;
    flex:1;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 1.8;
}

.costBox {
    border-top-style: dotted;
    border-top-color: #dcdcdc;
    border-top-width: 1.5px;
    margin-top:15px;
}

.dottedline{
    display:flex;
    flex:1;
    height:1px;
    border-bottom-style: dotted;
    border-bottom-color: #dcdcdc;
    border-bottom-width: 1.5px;
}

.dialog-val{
    display: flex;
    flex-direction: column;
    width:300px;
    min-width:300px;
    max-width:300px;
}

.dialog-val span{
    width:100%;
    box-sizing: border-box;
    text-align: center;
    padding:20px;
    border-bottom: 1px solid #dcdcdc; 
}

.dialog-setting{
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 500px;
    min-height: fit-content;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    padding:0px;
    box-sizing: border-box;

}

.dialog-setting >div{
    display: flex;
    padding: 0px 10px 0px 10px;
    height: 70px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    border-bottom: 1px solid #dcdcdc;
}

.dialog-setting >div>div:nth-child(1){
    display: flex;
    flex: 1;
}




  
.order-accept-dialog-on{
    display: flex;
    position:fixed;
    justify-content: center;
    align-items: center;
    top:0px;
    z-index: 1200;
    height:100vh;
    width: 100vw;
   background-color: #00000090;
}


  

.order-accept-dialog-on>div{
   
    display: flex;
    flex-direction: column;
   
     
 
    justify-content: center;
    align-items: center;
   
}



.order-accept-dialog-on>div>ul{
    background-color: #ffffff;
    list-style-type: none;
    padding:0px;
    margin:0px;
    
    width:400px;
    font-size: 16px;
}
.order-accept-dialog-on>div>ul>li{
    width: 100%;
    display: flex;
    flex:1;
   border-bottom:1px solid #dcdcdc;
}
 
.order-accept-dialog-on>div>ul>li:nth-child(4){
    width: 100%;
    display: flex;
    flex:1;
   border-bottom:0px;
}


.ckOnL{
 
    color:#ffffff;
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    padding: 25px 0px 25px 0px; 
    border-right:0.5px solid #dcdcdc;
    background-color: #323232;

    
}
.ckOffL{

    background-color: #f3f3f3;
 
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    padding: 25px 0px 25px 0px; 
    border-right:0.5px solid #dcdcdc;
    
}


.ckOnR{
 
    color:#ffffff;
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    padding: 25px 0px 25px 0px; 
    background-color: #323232;
    border-left:0.5px solid #dcdcdc;
    
}
.ckOffR{

    background-color: #f3f3f3;
 
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    padding: 25px 0px 25px 0px; 
    border-left:0.5px solid #dcdcdc;
    
}


.ckR{

    background-color: #f3f3f3;
    font-weight: 700;  
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    padding: 35px 0px 35px 0px; 
    border-left:0.5px solid #dcdcdc;
    
}

.ckL{

    background-color: #f3f3f3;
 
    font-weight: 700;
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    padding: 35px 0px 35px 0px; 
    border-right:0.5px solid #dcdcdc;
    
}


.order-accept-dialog-on>div>div{

    display: flex;

    justify-content: center;
    align-items: center;
   background-color: #f3f3f3;
  
   margin-top:20px; 
   font-weight: 700;


    flex-direction: row;
    width: 100%; 
}
.order-accept-dialog-on>div>div>span:nth-child(1){
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1; 
    padding:25px;
    border-right:0.5px solid #dcdcdc;
}
.order-accept-dialog-on>div>div>span:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1; 
    padding:25px;
    border-left:0.5px solid #dcdcdc;
}


.order-accept-dialog-off{
    display: none;
}

.order-button{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width:70px;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    max-height: 70px;
    min-height: 70px; 
    border-radius: 50px; 
    font-weight: 700;
    position:relative;
}

.order-button img{
    position:absolute;
    width:70px;
    height:70px;
}

.order-button div{
    position: absolute;
}
 

.circular-chart {
    display: block;
    margin: 10px auto;
    width:70px;
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    max-height: 70px;
    min-height: 70px; 
}

.circle {
    stroke: #048e7d;
    stroke-width: 2.8;
    fill: none;

    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

.circleTimeOut {
    stroke: #e83532;
    stroke-width: 2.8;
    fill: none;

    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}


.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.8;
}




.dialog-onoff{
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 500px;
    min-height: fit-content;
    width: 500px;
    min-width: 500px;
    max-width: 500px;
    padding:0px;
    box-sizing: border-box;

}

.dialog-onoff >span{
    height:70px;
    min-height: 70px;
    padding:0px 15px 0px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #b7b7b7;
    position: fixed;
    width:500px;
    box-sizing: border-box;
    background-color: #ffffff;
    z-index: 9999;
}
.dialog-onoff >div{
    display: flex;
    padding: 15px; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    border-bottom: 1px solid #b7b7b7;
}

.dialog-onoff >div>div:nth-child(1){
    display: flex;
    flex: 1;
}




.storeOnOff{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.onoffImg{
    width:30px;
    min-width: 30px;
    height:30px;
    margin-right: 10px;
}
 
.selectDateBox{
    display: flex;
    flex-direction:row;
}
 
.selectDateBox input{
    background-color: #00000000;
    outline: none;
    border:0px;
}
.selectDateOn{
    display: flex;
    align-items: center;
    justify-content: center; 
 
    
    border:1px solid #cccccc; 
    background-color: #ffffff; 
    width:fit-content; 
    padding:12px;
    box-sizing: border-box;
    border-radius: 5px; 
}
.selectDateOff{
    display: flex;
    align-items: center;
    justify-content: center; 
 
    
    border:1px solid #cccccc;
    width:fit-content; 
    padding:12px;
    box-sizing: border-box;
    border-radius: 5px; 
}

.calculateLeft{
    width:30%;
    min-width: 30%;
    max-width: 30%;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow: scroll;
    box-sizing: border-box;
    scrollbar-width: none;
}

.calculateLeft::-webkit-scrollbar{
    display: none;
}

.calculateLeftTitle{
    /* display: flex;
    flex-direction: row;
    width:30vw;
    left:1px;
    padding:15px 0px 0px 15px;
    box-sizing: border-box;
    justify-content: center;
    position: fixed;
    background-color: #eeeeee; */

    display: flex;
    flex-direction: column;
    width:30vw;
    left:1px;
    padding:15px 0px 0px 15px;
    box-sizing: border-box;
    justify-content: center;
    position: fixed;
    background-color: #eeeeee;
}


.calculateDateOn{
    height:50px;
    min-height:50px;
    max-height:50px;
    display: flex;
    align-items: center;
    justify-content: center; 

    font-size: 15px; 
    
    border:4px solid #969696; 
    background-color: #ffffff; 
    width:calc(100% - 10px); 
    min-width:calc(100% - 10px); 
    max-width:calc(100% - 10px); 
    padding:12px;
    box-sizing: border-box;
    margin-bottom:5px;
}
.calculateDateOff{
    height:50px;
    min-height:50px;
    max-height:50px;
    display: flex;
    align-items: center;
    justify-content: center; 
    font-size: 15px; 
    border:1px solid #cccccc;
    width:calc(100% - 10px); 
    min-width:calc(100% - 10px); 
    max-width:calc(100% - 10px); 
    padding:15px;
    box-sizing: border-box;
    margin-bottom:5px;
}

.calculateLeftTitle input{
    height:50px;
    min-height:50px;
    max-height:50px;
    border: 0px;
    outline: 0px;
    background-color: #00000000;
    width:calc(100% - 10px); 
    min-width:calc(100% - 10px); 
    max-width:calc(100% - 10px); 
} 


.calculateLeftList{
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.calculateLeftList div{
    padding:20px 15px 20px 15px;
    text-align:left;
}

.calculateLeft img{
    width:15px;
    height:15px;
    margin-right: 10px;
}

.calculateRight{
    color:#5d5d5d;
    font-size: 15px;
    margin-left:1px;
    width:70%;
    min-width: 70%;
    max-width: 70%;
    padding:15px 15px 15px 0px;
    box-sizing: border-box;
}


.calculateRightLayout{
    width:100%;
    height:100%;
    background-color: #ffffff;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    overflow: scroll;
    scrollbar-width: none;
}

.calculateRightLayout::-webkit-scrollbar{
    display: none;
}

.calculateTop{
    width:calc(70vw - 17px); 
    position: fixed;
    display: flex;
    flex-direction: row;
    flex:1;
    padding:10px 5px 10px 15px;
    box-sizing: border-box;
    font-weight: 700;
    font-size:20px;
    align-items: center;
    height:60px;
    min-height: 60px;
    max-height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid #dcdcdc;
    
}

.valOn{
    box-sizing: border-box;
    height:45px;
    min-height: 45px;
    max-height: 45px;
    width:70px;
    min-width: 70px;
    max-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:3px solid #5d5d5d;
    margin-left:5px;
    color:#323232;
} 
.valOff{
    box-sizing: border-box;
    height:45px;
    min-height: 45px;
    max-height: 45px;
    width:70px;
    min-width: 70px;
    max-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #dcdcdc;
    margin-left:5px;
    color:#96969690;
}

.calculateTop >span:nth-child(1){
    display: flex;
    text-align: left; 
}

.calculateTop >span:nth-child(2){
    flex:1;
    display: flex;
    justify-content: flex-end;
    text-align: right; 
}


.calculateList {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-bottom:1px solid #dcdcdc;
    padding:15px;
    font-size:15px;
    color:#5d5d5d;
}

.calculateList span{
    display: flex;
    flex-direction: row;
    padding:5px 0px 5px 0px;
}

.calculateList span >div:nth-child(1){
}
.calculateList span >div:nth-child(2){
    display: flex;
    flex:1;
    justify-content: flex-end;
}

.calculateCostBar{
    display: flex;
    flex-direction: column;
    flex:1;
    box-sizing: border-box;
     
}

.calculateCostBar >span{
    display: flex;
    flex-direction: row;
    font-weight: 700;
    color:#5d5d5d;
    flex:1;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
}

.calculateCostBar >span >div:nth-child(1){ 
    border-right:1px solid #dcdcdc;
    padding:15px 15px 15px ;
}
.calculateCostBar >span >div:nth-child(2){
    padding:15px;
    display: flex;
    flex:1;
    justify-content: flex-end;
}
 
/* 
.calculateDateOn{
    flex:1;
    margin-right:10px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.calculateDateOff{
    flex:1;
    margin-right:10px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcdcdc;
} */